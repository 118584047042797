import React, { memo } from "react";
import { useSelector } from "react-redux";
import {
  SETTINGS_SCREEN_APP,
  SETTINGS_SCREEN_PAYMENT,
} from "enums/settingsScreenType";
import { getIsGifterStatisticsPageEnabled } from "src/features/gifterStatistics/soc/gifterStatisticsSoc";
import {
  SocialGamesAnalyticEventName,
  SocialGamesScreenName,
  SocialGamesTargetType,
} from "src/features/socialGames/exports/common";
import useSocialGamesMenuItemEnabled from "src/features/socialGames/hooks/useSocialGamesMenuItemEnabled";
import {
  getIsNewSettingsPageEnabled,
  getIsRedesignedReferralProgramEnabled,
  getSubscribeMenuItemEnabled,
  isLocalSubscriptionsEnabled,
} from "state/abTests";
import DropdownListItem from "ui/common/dropdownMenu/menuList/components/DropdownListItem";
import ExploreNavigationDarkModeMenuItem from "ui/common/dropdownMenu/menuList/components/ExploreNavigationDarkModeMenuItem";
import sharedMessages from "ui/common/intl/sharedMessages";
import useUiAction from "ui/hooks/useUiAction";
import {
  linkToGifterStatistics,
  linkToNFTAuction,
  linkToProfileSettingsPage,
  linkToReferralProgram,
  linkToSocialGamesBase,
  makeLinkToSettingsScreen,
} from "ui/navigation/links";
import useAuctionEnabled from "ui/nft/hooks/useAuctionEnabled";
import FanZoneItem from "./FanZoneItem";
import MyFansItem from "./MyFansItem";
import { ReactComponent as PaymentSettingsIcon } from "img/new-ui/burger_menu/ic-payment-settings.svg";
import { ReactComponent as ReferralIcon } from "img/new-ui/burger_menu/ic-referral-link.svg";
import { ReactComponent as SettingsIcon } from "img/new-ui/burger_menu/ic-settings.svg";
import { ReactComponent as GifterStatisticsIcon } from "img/new-ui/burger_menu/statistics.svg";
import { ReactComponent as NftAuctionIcon } from "img/user_menu_icons/nft_auction.svg";
import { ReactComponent as SocialGamesIcon } from "img/user_menu_icons/social-games.svg";

const AuthorizedUserItems: React.FC = () => {
  const isAuctionEnabled = useAuctionEnabled();
  const isSocialGamesEnabled = useSocialGamesMenuItemEnabled();
  const isSubscriptionsEnabled = useSelector(isLocalSubscriptionsEnabled);
  const isSubscribeMenuItemEnabled = useSelector(getSubscribeMenuItemEnabled);
  const isNewSettingEnabled = useSelector(getIsNewSettingsPageEnabled);
  const isRedesignedReferralProgramEnabled = useSelector(
    getIsRedesignedReferralProgramEnabled
  );
  const isGifterStatisticsEnabled = useSelector(
    getIsGifterStatisticsPageEnabled
  );

  const reportGoNFTAuctionUiAction = useUiAction({
    target: "nft_auction",
  });

  const reportGoSocialGamesUiAction = useUiAction({
    [SocialGamesAnalyticEventName.TARGET]:
      SocialGamesTargetType.SOCIAL_GAMES_ENTER,
    additionalParams: {
      [SocialGamesAnalyticEventName.SCREEN_NAME]: SocialGamesScreenName.PROFILE,
    },
  });

  return (
    <>
      {isRedesignedReferralProgramEnabled && (
        <DropdownListItem
          Icon={ReferralIcon}
          titleMessage={sharedMessages.agencyReferralProgram}
          data-testid="virality-button"
          to={{
            pathname: linkToReferralProgram,
            state: { source: "banner_profile" },
          }}
        />
      )}
      {isSubscriptionsEnabled && isSubscribeMenuItemEnabled && (
        <>
          <FanZoneItem />
          <MyFansItem />
        </>
      )}
      {isAuctionEnabled && (
        <DropdownListItem
          to={linkToNFTAuction}
          data-testid="nftAuction"
          onClick={reportGoNFTAuctionUiAction}
          Icon={NftAuctionIcon}
          titleMessage={sharedMessages.auction}
        />
      )}
      {isSocialGamesEnabled && (
        <DropdownListItem
          to={linkToSocialGamesBase}
          Icon={SocialGamesIcon}
          data-testid="socialGames"
          onClick={reportGoSocialGamesUiAction}
          titleMessage={sharedMessages.socialGames}
        />
      )}
      {isGifterStatisticsEnabled && (
        <DropdownListItem
          to={linkToGifterStatistics}
          Icon={GifterStatisticsIcon}
          data-testid="statistics"
          titleMessage={sharedMessages.gifterStatistics}
        />
      )}
      {isNewSettingEnabled && (
        <DropdownListItem
          to={linkToProfileSettingsPage}
          Icon={SettingsIcon}
          data-testid="settings"
          titleMessage={sharedMessages.settings}
        />
      )}
      <DropdownListItem
        to={makeLinkToSettingsScreen(SETTINGS_SCREEN_PAYMENT)}
        Icon={PaymentSettingsIcon}
        data-testid="payment-settings"
        titleMessage={sharedMessages.paymentSettings}
      />
      {!isNewSettingEnabled && (
        <DropdownListItem
          to={makeLinkToSettingsScreen(SETTINGS_SCREEN_APP)}
          Icon={SettingsIcon}
          data-testid="app-settings"
          titleMessage={sharedMessages.appSettings}
        />
      )}
      {!isRedesignedReferralProgramEnabled && (
        <DropdownListItem
          Icon={ReferralIcon}
          titleMessage={sharedMessages.referralProgram}
          data-testid="virality-button"
          to={{
            pathname: linkToReferralProgram,
            state: { source: "banner_profile" },
          }}
        />
      )}

      <ExploreNavigationDarkModeMenuItem />
    </>
  );
};

export default memo(AuthorizedUserItems);
