import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { PopoverPosition } from "src/enums";
import styles from "./NativePopover.scss";

const LONG_PRESS_DELAY_MS = 500;

interface NativePopoverProps {
  children: ReactNode;
  content: ReactElement;
  contentClassName?: string;
  isOpen: boolean;
  onChange: (open: boolean) => void;
  position?:
    | "bottom"
    | "bottomLeft"
    | "bottomRight"
    | "left"
    | "leftBottom"
    | "leftTop"
    | "right"
    | "rightBottom"
    | "rightTop"
    | "top"
    | "topLeft"
    | "topRight";
  wrapperClassName?: string;
}

/**
 * * `NativePopover` component.
 *  * A popover that delivers a native-like experience by allowing users to open it through long press or right-click actions.
 *  * This component mimics the behavior of standard context menus found in native applications.
 *
 * @returns JSX.Element - The rendered NativePopover component.
 *
 * @example
 * <NativePopover
 *   isOpen={isPopoverOpen}
 *   onChange={setIsPopoverOpen}
 *   content={<div>Popover Content</div>}
 *   position="top"
 * >
 *   <button>Long Press or Right Click to Open</button>
 * </NativePopover>
 */

export const NativePopover: FC<NativePopoverProps> = ({
  children,
  content,
  position = PopoverPosition.TOP,
  contentClassName,
  wrapperClassName,
  isOpen,
  onChange,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const handleLongPress = useCallback(() => {
    onChange(true);
  }, [onChange]);

  const startLongPress = useCallback(() => {
    const id = setTimeout(() => {
      handleLongPress();
    }, LONG_PRESS_DELAY_MS);
    setTimeoutId(id);
  }, [handleLongPress]);

  const handlePressEnd = useCallback(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  }, [timeoutId]);

  const handleRightClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault(); // Prevent default context menu from showing
      onChange(true);
    },
    [onChange]
  );

  const handleClickOutside = useCallback(
    (event) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onChange(false);
      }
    },
    [onChange]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
      handlePressEnd();
    };
  }, [handleClickOutside, handlePressEnd]);

  return (
    <div ref={wrapperRef} className={classNames(styles.root, wrapperClassName)}>
      <div
        onMouseDown={startLongPress}
        onMouseUp={handlePressEnd}
        onTouchStart={startLongPress}
        onTouchEnd={handlePressEnd}
        onContextMenu={handleRightClick}
        className={styles.trigger}
      >
        {children}
      </div>
      <div
        hidden={!isOpen}
        className={classNames(
          styles.contentWrapper,
          styles[position],
          contentClassName
        )}
      >
        <div className={styles.content}>{content}</div>
      </div>
    </div>
  );
};

NativePopover.displayName = "Popover";
